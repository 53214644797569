/* ----- Fonts ----- */

/* Style de base */
body {
    @apply font-body overflow-x-hidden font-normal;
}

h1 {
    @apply font-heading;
}
h2 {

}
h3 {

}
a, svg, *::before, *::after {
    @apply transition-all;
}
img {
    @apply inline-block;
}

main {
    & p {
        @apply mb-2;
    }
    a {
        @apply text-primary-500 hover:text-primary-700;
    }
}


@keyframes anim-1 {
    50% {
        @apply opacity-0 translate-x-4;
    }
    51% {
        @apply opacity-0 -translate-x-4 top-2;
    }
    100% {
        @apply opacity-100 translate-x-0 top-2;
    }
}
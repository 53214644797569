@import "tailwindcss/base";
/* ----- Custom base styles ----- */
@import "./base.css";

@import "tailwindcss/components";
/* ----- Custom components ----- */
@import "./components.css";

@import "tailwindcss/utilities";
/* ----- Custom utilities ----- */


/* Site CSS */

/* Global */
.form-item:not(.form-checkbox) {
    @apply relative overflow-hidden pt-8 pb-2 mb-4;

    & label {
        @apply absolute bottom-0 left-0 px-1 w-full h-full text-grey-500 pointer-events-none;

        & span {
            @apply absolute bottom-2;
        }

        &::before, &::after {
            content: '';
            @apply absolute left-0 top-0 w-full h-full border-b-2 border-grey-500;
        }

        &::after {
            @apply border-b-4 border-primary -translate-x-full transition-all;
        }
    }

    & input, & textarea {
        @apply w-full px-1 focus:text-primary focus:outline-none bg-inherit;

        &:focus ~ label, &.active ~ label {
            & span {
                animation: anim-1 0.3s forwards;
            }

            &::after {
                @apply translate-x-0;
            }
        }
    }
}


.contact-bien .form-checkbox span.active{
    @apply flex-none
}
/* Header */
header {
    @apply transition-all;

    & nav {
        & .grid {


            & ul {
                & a {
                    @apply block text-2xl lg:text-3xl text-center sm:text-left hover:font-bold hover:text-primary hover:tracking-wide mb-4 lg:mb-8;
                }

                li.nav-active a {
                    @apply font-bold text-primary tracking-wide;
                }

                & li:last-of-type a {
                    @apply text-sm;
                }
            }
        }

        & .nav-img {
            @apply absolute right-0 top-0 w-3/5 h-full z-1;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);

            &::before, &::after {
                content: '';
                @apply hidden lg:block absolute left-0 h-full bg-primary z-5;
            }

            &::before {
                @apply top-0 -left-12 xl:left-0 w-32 bg-opacity-30;
                clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%);
            }

            &::after {
                @apply -left-40 xl:-left-1/4 bottom-0 w-1/2 bg-opacity-60;
                clip-path: polygon(0 0, 30% 0, 100% 100%, 0 100%);
            }

            & img {
                @apply hidden w-full h-full object-cover;

                &:first-of-type {
                    @apply sm:block;
                }
            }
        }
    }

    &.scrollingdown {
        & > .flex {
            @apply bg-white py-2 px-4 items-center rounded-full shadow-xl;

            & .logo img {
                @apply !w-24;
            }

            & svg {
                @apply text-grey-900 hover:text-primary;
            }
        }
    }

    &.nav-open {
        & nav {
            @apply left-0;
        }

        & > .flex > div {
            &:first-of-type svg {
                @apply text-grey-900;
            }

            &:last-of-type svg {
                @apply text-grey-900 sm:text-white;
            }
        }

        & .logo img {
            @apply !w-40;
        }
    }
}

/* Contenu */
#home {
    &-hero {
        clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);

        & .bg-img {
            &::before {
                content: '';
                 @apply absolute left-0 top-0 w-full h-full bg-black bg-opacity-30;
                 z-index: 2;
            }

            & img {
                @apply w-full h-full object-cover object-bottom;
            }
        }

        &::before {
            content: '';
            @apply absolute left-0 -bottom-4 h-7 lg:h-12 w-3/5 bg-primary bg-opacity-30;
        }

        &::after {
            content: '';
            clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
            @apply absolute right-0 bottom-0 xl:bottom-4 h-16 lg:h-20 w-3/5 bg-primary bg-opacity-60;
        }
    }

    &-details {
        & .panels-nav {
            & ul {
                @apply relative h-full flex flex-col justify-between;

                &::before {
                    content: '';
                    @apply absolute top-0 x-center w-px h-full bg-primary;
                }

                & a {
                    @apply relative block w-4 h-4 border border-primary bg-white rounded-full hover:bg-primary-300 z-1;

                    &.active {
                        @apply bg-primary;
                    }
                }
            }
        }

        & .module {
            @apply pointer-events-none;
            & > div {
                @apply pointer-events-auto;
            }
        }

        & .module-video {
            p {
                all: unset;
                iframe {
                    @apply absolute top-0 left-0 bottom-0 right-0 w-full h-full bg-grey-100 pointer-events-auto lg:pl-56 xl:pl-72;
                }
            }
        }
        & .section:nth-of-type(2n) iframe {
            @apply lg:bg-grey-300;
        }
    }

    &-gammes {
        &::before, &::after {
            content: '';
            @apply absolute top-0 w-45p h-120 opacity-5 -z-1;
        }

        &::before {
            @apply -left-40;
            background: url('/img/bg/bg-gammes-01.jpg') no-repeat;
            background-size: cover;
        }

        &::after {
            @apply right-0;
            background: url('/img/bg/bg-gammes-02.jpg') no-repeat;
            background-size: cover;
        }

        & #slider-gammes {
            & .swiper-slide {
                @apply transition-all;

                & > a::before {
                    content: '';
                    @apply absolute left-0 top-0 w-full h-full bg-primary bg-opacity-0;
                }

                &:hover > a::before {
                    @apply bg-opacity-50;
                }

                & img {
                    @apply w-full h-full object-cover;
                }

                &-active {
                    @apply h-88;
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                }

                &-before {
                    @apply h-104;
                    clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
                }

                &-before-2 {
                    @apply mt-17;
                }

                &-after {
                    @apply h-104;
                    clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);
                }

                &-after-2 {
                    @apply mt-17;
                }
            }
        }

    }

    &-presentation {
        .intro-images {
            p:first-of-type img {
                @apply block relative lg:ml-auto max-h-120 lg:max-h-full w-11/12 object-cover;
            }

            p:last-of-type img {
                @apply block relative w-3/5 -mt-12 xs:-mt-24 ml-auto right-4 md:right-0 lg:-right-24 xl:-right-32 object-cover;
            }
        }
    }

    &-maison {
        clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);

        & .bg-img {
            &::before {
                content: '';
                @apply absolute left-0 top-0 w-full h-full bg-grey-900 bg-opacity-60 z-1;
            }
            img {
                @apply w-full h-full object-cover;
            }
        }

        &::before {
            content: '';
            @apply absolute left-0 bottom-0 h-7 lg:h-12 w-3/5 bg-primary bg-opacity-30;
        }

        &::after {
            content: '';
            clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
            @apply absolute right-0 bottom-4 lg:bottom-8 h-16 lg:h-20 w-3/5 bg-primary bg-opacity-60;
        }

        & .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
            @apply bg-primary;
        }
    }
}

.card-bien button{
    @apply text-white border border-white hover:bg-white hover:text-primary
}

.content-card{
    clip-path: polygon(0 8%, 100% 0%, 100% 100%, 0% 100%);
}

header nav .grid ul a{
    @apply text-xl xxl:text-2xl mb-4
}
@screen lg {
    #home-maison {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    }
    .card-bien button{
        @apply text-primary border border-primary hover:bg-primary hover:text-white
    }
}



.transitioning{
    transition: all 300ms ease-in-out;
}

#actualites {
    &::before {
        content: '';
        @apply absolute left-0 -top-4 w-1/2 h-110p opacity-25 -z-1;
        background: url('/img/bg/bg-actu.jpg') repeat-y;
    }

    & article {
        & > a::before {
            content: '';
            @apply absolute left-0 top-0 w-full h-full bg-primary bg-opacity-0;
        }

        &:hover > a::before {
            @apply bg-opacity-50;
        }
    }
}

.calendar {
    @apply min-w-20;

    & p {
        @apply text-center;

        &:first-of-type {
            @apply bg-white font-bold text-3xl text-primary p-1 mb-0;
        }

        &:last-of-type {
            @apply bg-primary text-sm text-white tracking-wider py-1 px-2 mb-0;
        }
    }
}

#home-gammes::before, #home-gammes::after{
    z-index: 1;
    pointer-events: none;
}

#hero {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    @apply relative px-8 sm:px-12 lg:px-32 xl:px-60 xxl:px-80 pt-32 md:pt-60 lg:pt-72 pb-12 md:pb-24;

    & h1 {
        @apply relative font-bold text-white text-4xl sm:text-5xl lg:text-7xl text-center z-5;
    }

    .bg-img {
        @apply absolute left-0 top-0 w-full h-full -z-1;

        & img {
            @apply w-full h-full object-cover -z-1;
        }

        &::before {
            content: '';
            @apply absolute left-0 top-0 w-full h-full bg-grey-900 bg-opacity-25 z-1;
        }
    }

    &::before {
        content: '';
        @apply absolute left-0 -bottom-4 h-7 lg:h-12 w-3/5 bg-primary bg-opacity-30;
    }

    &::after {
        content: '';
        clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
        @apply absolute right-0 bottom-0 xl:bottom-4 h-16 lg:h-24 w-3/5 bg-primary bg-opacity-60;
    }
}

.content {
    @apply px-8 sm:px-12 lg:px-32 xl:px-60 xxl:px-80 py-12 lg:py-24;

    & h2 {
        @apply text-primary text-4xl font-bold mt-4 mb-2;
    }

    & a:not(.btn) {
        @apply text-primary hover:text-primary-900;
    }

    & p {
        @apply mb-2;
    }

    & ul {
        @apply list-disc list-inside mb-2;
    }

    & ol {
        @apply list-decimal list-inside mb-2;
    }
}

.contact-img {
    & img {
        @apply block relative w-full sm:w-4/5 lg:w-full xl:w-4/5 mt-8 lg:-mt-24 mx-auto lg:mr-0 lg:-right-80 xxl:-right-100 object-cover;
    }
}

/* Gammes */
#liste-gammes {
    & > section {
        & > a {
            @apply w-full;
        }

        &:nth-of-type(even) {
            @apply flex-row-reverse;

            & > a {
                @apply right-0;

                &::before, &::after {
                    content: '';
                    @apply hidden lg:block absolute h-full bg-primary z-5;
                }

                &::before {
                    @apply left-0 w-8 bg-opacity-30;
                    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
                }

                &::after {
                    @apply left-0 bottom-0 w-20 bg-opacity-60;
                    clip-path: polygon(100% 0, 0 0, 0 100%);
                }
            }

            & > div {
                @apply pl-8 sm:pl-12 lg:pl-32 xl:pl-60 xxl:pl-80 pr-8 xl:pr-12 mr-auto;
            }

        }

        &:nth-of-type(odd) {
            & > a {
                @apply left-0;

                &::before, &::after {
                    content: '';
                    @apply hidden lg:block absolute h-full bg-primary z-5;
                }

                &::before {
                    @apply right-0 w-8 bg-opacity-30;
                    clip-path: polygon(0 0, 0% 100%, 100% 100%);
                }

                &::after {
                    @apply right-0 bottom-0 w-20 bg-opacity-60;
                    clip-path: polygon(100% 0, 0 0, 100% 100%);
                }
            }

            & > div {
                @apply pr-8 sm:pr-12 lg:pr-32 xl:pr-60 xxl:pr-80 pl-8 xl:pl-12 ml-auto;
            }

        }
    }
}
@screen lg {
    #liste-gammes {
        & > section {
            & > a {
                width: calc(50% + 1.5rem);
            }

            &:nth-of-type(even) {
                & > a {
                    clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
                }
            }

            &:nth-of-type(odd) {
                & > a {
                    clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
                }
            }
        }
    }
}

.slider-photos-controls {
    & .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        @apply bg-primary;
    }
      & .swiper-pagination-progressbar{
            @apply bg-grey-400
        }
}
@screen lg {
    #slider-photos {
        clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
    }
    #presentation #slider-photos {
        clip-path: none;
    }
}

.h-2px{
    height: 3px !important;
}

/* Réalisations */
#liste-realisations {
    & .filtre {
        &.inactif {
            @apply opacity-50;
        }
    }

    .hidden-cat, .hidden-topo, .hidden-city {
        @apply hidden;
    }

    & > section {
        &:nth-of-type(even) {
            @apply flex-row-reverse lg:pl-0 lg:pr-32 xl:pr-60 xxl:pr-80 bg-grey-100;
        }

        &:nth-of-type(odd) {
            @apply lg:pr-0 lg:pl-32 xl:pl-60 xxl:pl-80;

            &::before {
                content: '';
                @apply absolute left-0 top-0 sm:w-8 lg:w-28 xl:w-52 xxl:w-72 h-full opacity-25 -z-1;
                background: url('/img/bg/bg-actu.jpg') repeat-y;
            }
        }

        & .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
            @apply bg-primary;
        }
    }
}

#realisation>section{
    @apply px-8 lg:px-32 xl:px-60 xxl:px-80;
    &::before {
         content: '';
         @apply absolute left-0 top-0 sm:w-8 lg:w-28 xl:w-52 xxl:w-72 h-full opacity-25 -z-1;
         background: url('/img/bg/bg-actu.jpg') repeat-y;
     }
    & .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
          @apply bg-primary;
      }
}
@screen lg {
    #liste-realisations {
        & > section {
            &:nth-of-type(even) {
                & .slider-realisation {
                    clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
                }
            }

            &:nth-of-type(odd) {
                & .slider-realisation {
                    clip-path: polygon(5% 0%, 100% 0, 100% 100%, 0% 100%);
                }
            }
        }
    }
}

/* Qui sommes-nous */
#presentation {
    &::before {
        content: '';
        @apply absolute left-0 -top-4 sm:w-8 lg:w-28 xl:w-52 xxl:w-72 h-110p opacity-25 -z-1;
        background: url('/img/bg/bg-actu.jpg') repeat-y;
    }

    & h2 {
        @apply text-4xl font-bold mb-8;
    }

    & h3 {
        @apply text-primary font-bold text-xl mb-4;
    }
      ul{
          @apply list-disc pl-5;
      }
}

#slider-photos .swiper-slide>*{
    @apply opacity-0 transitioning
}

#slider-photos .swiper-slide-active>*{
    @apply opacity-100
}
#histoire {
    & section {
        & .histoire-img {
            @apply left-0 lg:absolute top-0 h-80 lg:h-full w-full lg:w-1/2;

            & img {
                @apply h-full w-full object-cover;
            }

            &::before, &::after {
                content: '';
                @apply hidden lg:block absolute h-full bg-primary z-5;
            }

            &::before {
                @apply right-0 w-8 bg-opacity-30;
                clip-path: polygon(0 0, 0% 100%, 100% 100%);
            }

            &::after {
                @apply right-0 bottom-0 w-20 bg-opacity-60;
                clip-path: polygon(100% 0, 0 0, 100% 100%);
            }
        }

        & .histoire-content {
            @apply bg-grey-100 w-full h-full lg:w-1/2 py-12 lg:pt-24 lg:pb-40 xl:pb-60 pr-8 sm:pr-12 lg:pr-32 xl:pr-60 xxl:pr-80 pl-8 xl:pl-12 ml-auto;
        }
    }

    & #slider-histoire-date {
        @apply w-full;

        & .swiper-slide {
            @apply cursor-pointer pointer-events-auto;

            &::before {
                content: '';
                @apply absolute x-center w-full bg-grey-300;
                height: 2px;
                top: 7px;
            }

            & span {
                @apply relative block w-4 h-4 bg-primary mx-auto mb-4 rounded-full;
            }

            &-active {
                & span::before {
                    content: '';
                    @apply absolute abs-center w-6 h-6 border border-primary rounded-full;

                }
            }

            & .calendar {
                @apply w-max mx-auto;
            }
        }
    }
}

@screen lg {
    #histoire {
        & section {
            & .histoire-img {
                clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
            }
        }

        & #slider-histoire-date {
            width: calc(50% + 1rem);
            clip-path: polygon(.5rem 0px, 100% 0px, 100% 100%, .9rem 100%);

            & .swiper-slide-active::before {
                @apply left-1/2;
            }
        }
    }
}
@screen xxl {
    #histoire {
        & #slider-histoire-date {
            clip-path: polygon(.1rem 0px, 100% 0px, 100% 100%, .8rem 100%);
        }
    }
}

#equipe {
    & .bg-img {
        &::before {
            content: '';
            @apply absolute left-0 top-0 w-full h-full bg-grey-900 bg-opacity-60 z-1;
        }
    }

    &::before {
        content: '';
        clip-path: polygon(0 0, 0 100%, 100% 100%);;
        @apply absolute left-0 bottom-0 h-7 lg:h-12 w-3/5 bg-primary bg-opacity-30;
    }

    &::after {
        content: '';
        clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
        @apply absolute right-0 bottom-0 h-16 lg:h-20 w-3/5 bg-primary bg-opacity-60;
    }

    & .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        @apply bg-primary;
    }
}

/* LA Maison Barberousse */
#presentation-maison {
    & h2 {
        @apply text-3xl sm:text-4xl lg:text-5xl leading-tight font-bold mb-8;
    }

    & .presentation-img {
        & iframe {
            @apply block relative lg:ml-auto h-96 lg:h-120 w-full lg:w-11/12 object-cover;
        }
    }
}

@keyframes pulsate {
    0% {
        opacity: 0.18;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 0.18;
    }
}

#galerie {
    & .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        @apply bg-primary;
    }

    & #maison {
        @apply text-primary;

        & g {
            @apply cursor-pointer;

            & * {
                @apply fill-transparent stroke-primary stroke-1;
                stroke-opacity: .35;
            }

            g[id^=btn] ellipse {
                @apply fill-primary relative;
                stroke-opacity: 1!important;
            }

            g[id^=btn] ellipse:nth-of-type(2) {
                animation: pulsate 1.25s ease-out infinite;
            }


            &.active {
                & * {
                    @apply stroke-2;
                    stroke-opacity: 1;
                }

                & g[id^=btn] ellipse {
                    @apply opacity-25;
                    animation: none;
                }
                & g[id^=btn] ellipse:first-of-type {
                    @apply opacity-0;
                }
            }
            &#ext.active {
                & * {
                    stroke-width: 3;
                }
            }
        }
    }
}

#tour {
    &::before {
        content: '';
        @apply absolute left-0 bottom-0 w-full h-full -z-1;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 55%, rgba(255, 255, 255, .5)), url('/img/bg/bg-actu.jpg') repeat-x bottom;
    }
}

.video-container {
    p {
        all:unset;
        iframe {
            @apply mx-auto w-full lg:w-3/4 h-auto;
            aspect-ratio :16/9;
        }
    }
}

.actu-content {
    iframe {
        @apply max-w-full;
    }
}

#slider-programmes{
    & .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
          @apply bg-primary;
      }
}
#slider-realisations-biens+div .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        @apply bg-primary;
}
#slider-realisations-biens+div .swiper-pagination-progressbar  {
     @apply bg-grey-300;
 }

#materiaux-maison {
    & #slider-materiaux-thumb {
        & .swiper-slide {
            @apply h-32 xxl:h-40 transition-all;

            & img {
                @apply w-full h-full object-contain;
            }

            &-active {
                @apply transform rotate-0;
            }

            &-before {
                @apply mt-8 transform rotate-0;
            }

            &-before-2 {
                @apply mt-32 transform -rotate-12;
            }

            &-after {
                @apply mt-8 transform rotate-0;
            }

            &-after-2 {
                @apply mt-32 transform rotate-12;
            }
        }

        & .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
            @apply bg-primary;
        }
    }
}

@screen lg {
    #materiaux-maison {
        clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
    }
}

#jeu-concours {
    clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);

    & .bg-img {
        &::before {
            content: '';
            @apply absolute left-0 top-0 w-full h-full bg-grey-900 bg-opacity-60 z-1;
        }
    }

    &::before {
        content: '';
        @apply absolute left-0 bottom-0 h-7 lg:h-12 w-3/5 bg-primary bg-opacity-30;
    }

    &::after {
        content: '';
        clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
        @apply absolute right-0 bottom-4 lg:bottom-8 h-16 lg:h-20 w-3/5 bg-primary bg-opacity-60;
    }

    ul {
        li {
            @apply pl-7 mb-2 relative;
            &::after {
                content: "";
                @apply w-5 h-5 text-primary mr-2 absolute left-0 mt-0.5;
                background-image: url("/img/icones/picto-checkbox.svg");
            }
        }
    }
}

@screen lg {
    #jeu-concours {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    }
}
/* Footer */
footer {
    & section {
        background: url('/img/bg/bg-footer.svg') no-repeat;
        background-size: cover;

        & h3 {
            @apply font-bold text-lg tracking-wide mb-2;
        }
    }

    & .gamme > a {
        clip-path: polygon(15% 0%, 100% 0, 100% 100%, 0% 100%);

        &::before {
            content: '';
            @apply absolute left-0 top-0 w-full h-full bg-primary bg-opacity-0;
        }

        &:hover::before {
            @apply bg-opacity-50;
        }
    }
}

#immodvisor-seal-widget-iframe {
    @apply hidden lg:block;
    height: 125px !important;
    bottom: 30px !important;
    top: auto !important;
    max-width: 30vw;
}


@screen lg {
    #imdw-rating { display: block !important;}
    .imdw-rating .imdw-rating-widget {
        width: 115px!important;
    }
    .imdw-rating .imdw-rating-widget .imdw-rating-content .imdw-rating-rating .imdw-rating-txt {
        font-size: 24px !important;
    }
    .imdw-rating .imdw-rating-widget .imdw-rating-content {
        padding: 20px 12px 10px 12px !important;
    }
}


@screen xl {
    #immodvisor-seal-widget-iframe {
        height: 140px !important;
        bottom: 100px !important;
        width: 150px !important;
    }
}


.module-titre h2 {
    @apply text-2xl xl:text-4xl text-primary mb-4 font-light;

    span {
        @apply block font-bold;
        text-decoration: none!important;
    }
}

.swiper-hidden-slide {
    display:none !important;
    float:left;
}

@screen lg {
    #slider-galerie {
        width: calc(100% - 12rem);
    }
}

.slider-galerie-controls {
    .swiper-galerie_prev.swiper-button-disabled,
    .swiper-galerie_next.swiper-button-disabled {
        opacity: 0.3;
        cursor: auto;
    }
}